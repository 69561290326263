import { Injectable } from '@angular/core';
import { LocalStorageService } from '@src/app/core/services';
import { User } from '@src/app/core/models/user.model';
import { Router } from '@angular/router';
import { NavigationExtras } from '@angular/router';

declare const Buffer: any;

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {}

  /**
   * Creates a deep clone of the given data object.
   *
   * @param {any} data - The object to be cloned.
   * @return {any} A deep clone of the given data object.
   */
  public clone(data: any): any {
    return JSON.parse(JSON.stringify(data));
  }

  /**
   * Checks if two values are deeply equal.
   *
   * @param {any} a - The first value to compare.
   * @param {any} b - The second value to compare.
   * @return {boolean} True if the values are deeply equal, false otherwise.
   */
  public isEqual(a: any, b: any): boolean {
    // Base case: If both values are strictly equal or both are empty strings, return true
    if (
      a === b ||
      ((a === '' || a === null) && (b === '' || b === null)) ||
      (a === null && b === null)
    ) {
      return true;
    }

    // Check for object type
    if (typeof a === 'object' && typeof b === 'object') {
      // Compare arrays
      if (Array.isArray(a) && Array.isArray(b)) {
        if (a.length !== b.length) {
          return false;
        }
        for (let i = 0; i < a.length; i++) {
          if (!this.isEqual(a[i], b[i])) {
            return false;
          }
        }
        return true;
      }

      // Compare objects
      const keysA = Object.keys(a || {});
      const keysB = Object.keys(b || {});

      if (keysA.length !== keysB.length) {
        return false;
      }
      for (const key of keysA) {
        if (!keysB.includes(key) || !this.isEqual(a[key], b[key])) {
          return false;
        }
      }
      return true;
    }

    // For other types (including primitives), use strict equality
    return false;
  }

  /**
   * Uploads a media file from the given event and returns a promise that resolves with the uploaded media data.
   *
   * @param {any} event - The event object containing the file to be uploaded.
   * @return {Promise<any>} A promise that resolves with the uploaded media data, including the source URL, form data, type, and name.
   */
  public uploadMedia(event: any): any {
    return new Promise((resolve, reject) => {
      if (event.target.files && event.target.files.length) {
        const media = event.target.files[0];
        const data: any = {
          src: '',
          formData: media,
          type: media.type,
          name: media.name,
        };
        if (media.type.indexOf('image') === -1) {
          data.src = data.formData;
          resolve(data);
        } else {
          const reader = new FileReader();
          const [file] = event.target.files;
          reader.readAsDataURL(file);
          /**
           * Sets the `src` property of the `data` object to the result of the `reader` and resolves the promise with the `data` object.
           *
           * @return {void} This function does not return anything.
           */
          reader.onload = () => {
            data.src = reader.result as string;
            resolve(data);
          };
        }
      } else {
        reject();
      }
    });
  }

  /**
   * The following method is used to convert the respective base64encoded data into a blob
   * @param dataURI
   * @returns {Blob}
   */
  public dataURItoBlob(dataURI: any): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = this.getMimetypeFromDataUri(dataURI);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    const dataView = new DataView(ab);
    return new Blob([dataView], { type: mimeString });
  }

  /**
   * The following method is used to convert the respective base64encoded data into a blob
   * @param unit8Array
   * @param type
   * @returns {Blob}
   */
  public Unit8ArrayToFile(unit8Array: any, type: string = ''): Blob {
    const byteString = atob(unit8Array.toString('base64'));
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array], { type });
  }

  /**
   * the following makes sure the given string is not null or empty
   * @param {string} value
   * @returns {boolean}
   */
  public isNotEmptyOrUndefined(value: any) {
    return value !== undefined || value !== '' || value.length > 0;
  }

  /**
   * The following method is used to convert the image file into Data URI
   * @param imageFile
   * @param callback
   */
  public imageFileToDataURI(imageFile: any, callback: any) {
    const file: File = imageFile,
      myReader: any = new FileReader();

    /**
     * Sets up an event handler for the `loadend` event of the `myReader` object.
     * When the event is triggered, it calls the `callback` function with the result of the `myReader` as a string.
     * The commented out code is an alternative implementation that trims the base64 prefix from the result before passing it to the `callback`.
     *
     * @param {Event} e - The event object representing the `loadend` event.
     * @return {void} This function does not return anything.
     */
    myReader.onloadend = (e: any) => {
      /*const trimIndex = myReader?.result.toString().indexOf('base64,');
      callback(myReader?.result.toString().substr(trimIndex + 7));*/
      callback(myReader?.result.toString());
    };

    myReader.readAsDataURL(file);
  }

  /**
   * The following method is used to convert the image file into Data URI
   * @param base64Img
   * @param callback
   */
  public formatBase64Image(base64Img: string, callback: any) {
    const img = base64Img;
    const trimIndex = img.indexOf('base64,');
    callback(img.substr(trimIndex + 7));
  }

  /**
   * The following method is used to check the dimensions of image
   * @param imageFile
   * @param callback
   */
  public imageFileDimensions(imageFile: any, callback: any) {
    const fReader: any = new FileReader();
    /**
     * Sets up an event handler for the `load` event of the `fReader` object.
     * When the event is triggered, it creates a new `Image` object and sets up an event handler for its `load` event.
     * When the `load` event is triggered, it calls the `callback` function with the width and height of the loaded image.
     *
     * @param {Event} event - The event object representing the `load` event.
     * @return {void} This function does not return anything.
     */
    fReader.onload = function () {
      const img = new Image();
      /**
       * Sets up an event handler for the `load` event of the `img` object.
       * When the event is triggered, it calls the `callback` function with the width and height of the loaded image.
       *
       * @param {Event} event - The event object representing the `load` event.
       * @return {void} This function does not return anything.
       */
      img.onload = function () {
        callback(img.width, img.height);
      };

      img.src = fReader.result.toString();
    };

    fReader.readAsDataURL(imageFile);
  }

  /**
   * The following checks if the given is a valid base64 string or not
   * @param {string} base64Img
   * @returns {boolean}
   */
  public isValidBase64(base64Img: string) {
    try {
      atob(base64Img);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Generates a SEO-friendly URL based on the provided link, name, and id.
   *
   * @param {string} link - The base URL for the generated URL.
   * @param {any} name - The name to be included in the generated URL. If null or undefined, it will be ignored.
   * @param {number | string} id - The id to be included in the generated URL. If null or undefined, it will be ignored.
   * @return {string} The generated SEO-friendly URL.
   */
  public generateSeoFriendlyUrl(link: string, name: any, id: number | string): string {
    const slugName = name ? this.formatString(name).toLowerCase() : null;
    if (id) {
      return `${link}/${slugName ? slugName + '-' + id : id || ''}`;
    }
    return `${link}/${slugName ? slugName : ''}`;
  }

  /**
   * Retrieves the ID from a SEO-friendly URL.
   *
   * @param {string | null} link - The SEO-friendly URL from which to extract the ID.
   * @return {any} The extracted ID as a number, or null if the link is null or does not contain a valid ID.
   */
  public getIdFromSeoFriendlyUrl(link: string | null): any {
    if (!link) {
      return null;
    }
    const numberRegex = new RegExp(/^\d+$/);
    // Return if link is already an id
    if (numberRegex.test(link)) {
      return Number(link);
    }
    const id = link.split(/[-]+/).pop();
    return id && numberRegex.test(id) ? Number(id) : null;
  }

  /**
   * Extracts the MIME type from a Data URI.
   *
   * @param {string} dataURI - The Data URI from which to extract the MIME type.
   * @return {string} The extracted MIME type.
   */
  public getMimetypeFromDataUri(dataURI: string): string {
    return dataURI.split(',')[0].split(':')[1].split(';')[0];
  }

  /**
   * Formats a string by replacing non-alphanumeric characters and underscores with hyphens.
   *
   * @param {string} str - The string to be formatted. Defaults to an empty string if not provided.
   * @return {string} The formatted string.
   */
  public formatString(str: string = ''): string {
    return `${str}`.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '');
  }

  public getMiddleFive = (arr: any, needFirstLast = false) => {
    if (arr.length < 6) {
      if (needFirstLast) {
        return arr.slice(0, arr.length).slice(0, 5);
      } else {
        return arr.slice(1, arr.length - 1).slice(0, 5);
      }
    } else {
      // Calculate the middle index (integer division using Math.floor)
      const middleIndex = Math.floor(arr.length / 2);

      // Define starting and ending indexes with boundary checks
      const startIndex = Math.max(0, middleIndex - 2);
      const endIndex = Math.min(arr.length, middleIndex + 3);

      // Return the sliced array containing the middle elements
      return arr.slice(startIndex, endIndex);
    }
  };

  /**
   * Retrieves the value of a nested property from an object.
   *
   * @param {any} theObject - The object from which to retrieve the nested property value.
   * @param {string} path - The path to the nested property, using dot notation or array notation.
   * @param {string} [separator='.'] - The separator used to split the path into individual property names.
   * @return {string} The value of the nested property, or an empty string if the property does not exist.
   */
  getNestedPropertyValue(theObject: any, path: string, separator = '.'): string {
    try {
      separator = separator || '.';
      const pathsList = path.replace('[', separator).replace(']', '').split(separator);
      return pathsList.reduce((obj, property, index) => {
        // Checking for array
        if (property.indexOf('*') > -1) {
          if (Array.isArray(obj)) {
            return obj || [];
          }
          return (obj[pathsList[index - 1]] || []).map((i: any) => {
            return i[pathsList[index + 1]];
          });
        }
        if (Array.isArray(obj)) {
          return obj.map((i: any) => {
            return i[property];
          });
        }
        return obj[property];
      }, theObject);
    } catch (err) {
      return '';
    }
  }

  /**
   * Converts the given number of minutes to seconds.
   *
   * @param {number} minutes - The number of minutes to convert.
   * @return {number} The converted value in seconds.
   */
  public convertMinutesToSeconds(minutes: number) {
    return minutes * 60;
  }

  /**
   * Converts the given number of seconds to minutes.
   *
   * @param {number} seconds - The number of seconds to convert.
   * @return {number} The converted value in minutes.
   */
  public convertSecondsToMinutes(seconds: number) {
    return seconds / 60;
  }

  /**
   * Retrieves the dimensions of an image file and calls the provided callback function with the width and height.
   *
   * @param {File} image - The image file to retrieve dimensions from.
   * @param {Function} cb - The callback function to call with the width and height of the image.
   * @return {void}
   */
  public getImageDimensions(image: File, cb: Function) {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    /**
     * Sets up an event handler for the `load` event of the `reader` object.
     * When the event is triggered, it creates a new `Image` object and sets up an event handler for its `load` event.
     * When the `load` event is triggered, it calls the `cb` function with the width and height of the loaded image.
     *
     * @param {Event} event - The event object representing the `load` event.
     * @return {void} This function does not return anything.
     */
    reader.onload = () => {
      const img = new Image();
      img.onload = function () {
        // image is loaded; sizes are available
        cb({
          width: img.width,
          height: img.height,
        });
      };
      img.src = reader.result as string; // is the data URL because called with readAsDataURL
    };
  }

  /**
   * Following method will convert base64 to File
   * @param dataurl
   * @param filename
   * @returns
   */
  public dataURLtoFile(dataurl: any, filename: any) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  /**
   * Following method will remove requested props and return a new object
   * @param obj
   * @param keys
   * @returns
   */
  public objectWithoutProperties(obj: any, keys: string[]) {
    const target: any = {};
    for (let i in obj) {
      if (keys.indexOf(i) >= 0) continue;
      if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
      target[i] = obj[i];
    }
    return target;
  }

  /**
   * Converts the given number of seconds to hours.
   *
   * @param {number} seconds - The number of seconds to convert.
   * @return {number} The converted value in hours.
   */
  public secondsToHours(seconds: number) {
    return this.naiveRound(seconds / 3600, 2);
  }

  /**
   * Rounds a number to a specified number of decimal places.
   *
   * @param {number} num - The number to be rounded.
   * @param {number} [decimalPlaces=0] - The number of decimal places to round to. Defaults to 0.
   * @return {number} The rounded number.
   */
  public naiveRound(num: number, decimalPlaces = 0) {
    const p = Math.pow(10, decimalPlaces);
    return Math.round(num * p) / p;
  }

  /**
   * Checks if the given data is an object.
   *
   * @param {any} data - The data to check.
   * @return {boolean} Returns true if the data is an object, false otherwise.
   */
  public isObject(data: any): boolean {
    return typeof data === 'object' && !Array.isArray(data) && data !== null;
  }

  /**
   * Compares two time strings and returns true if the first time is greater than the second time.
   *
   * @param {string} time1 - The first time string in the format 'HH:mm'.
   * @param {string} time2 - The second time string in the format 'HH:mm'.
   * @return {boolean} True if time1 is greater than time2, false otherwise.
   */
  public isTimeGreaterThan(time1: string, time2: string): boolean {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    const date1 = new Date(0, 0, 0, hours1, minutes1);
    const date2 = new Date(0, 0, 0, hours2, minutes2);

    return date1 < date2;
  }

  /**
   * Converts the given number of hours to a string representation in the format 'HH h MM m'.
   *
   * @param {number} hours - The number of hours to convert.
   * @return {string} The converted value in the format 'HH h MM m'.
   */
  public convertHoursToHHMM(hours: number): string {
    // Split the hours into integer and fractional parts
    const integerPart = Math.floor(hours);
    const fractionalPart = hours - integerPart;

    // Calculate the minutes from the fractional part
    const minutes = Math.round(fractionalPart * 60);

    // Construct the result
    let result = '';
    if (integerPart > 0) {
      result += `${integerPart} h `;
    }
    if (minutes > 0) {
      result += `${minutes} m`;
    }

    return result;
  }

  public roundTo(n: number, digits: number | undefined) {
    if (digits === undefined) {
      digits = 0;
    }
    let multiplicate = Math.pow(10, digits);
    n = parseFloat((n * multiplicate).toFixed(11));
    let test = Math.round(n) / multiplicate;
    return +test.toFixed(digits);
  }

  /**
   * Retrieves the translated text for a given key based on the current language setting.
   *
   * @param {any} key - The key used to retrieve the translated text.
   * @return {string} The translated text for the given key. If the key is not found, an empty string is returned.
   */
  getTranslatedText(key: any) {
    let lang =
      this.localStorageService.getDataInLocalStorage('language', false)?.code?.toLowerCase() ||
      'fr';

    const locale: any = lang || 'fr';

    // Check if key is defined, if not, provide an empty object as a default value
    const translatedKey = key ?? {};

    return translatedKey[locale] === ''
      ? locale === 'fr'
        ? translatedKey['en']
        : translatedKey['fr']
      : translatedKey[locale];
  }

  /**
   * Fills empty languages in the given data object with values from the 'fr' and 'en' languages.
   *
   * @param {any} data - The data object to fill empty languages in.
   * @return {any} The updated data object with filled empty languages. If the data object is falsy, an object with empty 'en' and 'fr' languages is returned.
   */
  fillEmptyLanguages(data: any): any {
    if (data) {
      const languages = Object.keys(data);

      // Loop through each language
      for (const lang of languages) {
        if (lang !== 'fr' && !data[lang]) {
          data[lang] = data['fr'];
        }
        if (lang !== 'en' && !data[lang]) {
          data[lang] = data['en'];
        }
      }
    }
    if (!data) {
      return { en: '', fr: '' };
    }
    return data;
  }

  /**
   * Sorts an array of objects by a specified key in ascending order.
   *
   * @param {any[]} array - The array to be sorted.
   * @param {string | number} key - The key to sort the array by.
   * @return {any[]} The sorted array.
   */
  sortByKey(array: any[], key: string | number) {
    return array.sort(function (a: { [x: string]: any }, b: { [x: string]: any }) {
      let x = a[key];
      let y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
  /**
   * Checks if the given object contains any non-empty language properties.
   *
   * @param {any} data - The object to check for empty language properties.
   * @return {boolean} Returns `false` if the object contains at least one non-empty language property, otherwise returns `true`.
   */
  isLangObjectEmpty(data: any) {
    if (data) {
      const languages = Object.keys(data);

      // Loop through each language
      for (const lang of languages) {
        if (data[lang]) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Retrieves the current user from the local storage.
   *
   * @return {User | null} The current user object if the user is logged in, otherwise null.
   */
  getCurrentUser() {
    const user = this.localStorageService.getDataInLocalStorage('senegal-user');
    return user?.id ? new User(user) : null;
  }

  /**
   * Navigates to the home page based on the stored return URL and user information.
   * If a return URL and user are present in local storage, the user is redirected to the corresponding URL if their employee ID matches the return URL ID or if the return URL ID is 'notFound'.
   * Otherwise, the user is redirected to the home page.
   * If no return URL or user is present in local storage, the user is also redirected to the home page.
   */
  goToHome() {
    let returnUrl = JSON.parse(localStorage.getItem('returnUrl') || 'null');
    let user = JSON.parse(localStorage.getItem('senegal-user') || 'null');
    if (returnUrl && user) {
      localStorage.removeItem('returnUrl');
      if (user.employeeId === returnUrl.id || returnUrl.id === 'notFound') {
        this.router.navigateByUrl(returnUrl.url);
      } else {
        this.router.navigate(['/']);
      }
    } else {
      this.router.navigate(['/']);
    }
  }
  /**
   * Checks if the user is logged in by retrieving the token from local storage and returning a boolean value.
   *
   * @return {boolean} Returns true if the token exists in local storage, false otherwise.
   */
  isLogged() {
    return !!this.localStorageService.getToken();
  }

  goToRoute(link: string, extras?: NavigationExtras): void {
    this.router.navigate([link], extras);
  }
}
