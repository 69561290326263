import { Injectable } from '@angular/core';
import { HttpService } from '@src/app/core/services';
import { USER_REFRESH_TOKEN_BASE_URL } from '../constants';
import {
  GET_USER_DETAILS,
  LOGOUT,
  GET_DEFAULTS_BASE_URL,
} from '@src/app/core/constants/apis-list.constant';

@Injectable({
  providedIn: 'root',
})
export class AuthRepository {
  constructor(private httpService: HttpService) {}

  resetToken() {
    return this.httpService.requestEntity('POST', USER_REFRESH_TOKEN_BASE_URL, {});
  }
  getUserDetails() {
    return this.httpService.requestEntity('GET', GET_USER_DETAILS);
  }
  getBaseURL() {
    return this.httpService.requestEntity('GET', GET_DEFAULTS_BASE_URL);
  }
  logout() {
    return this.httpService.requestEntity('GET', LOGOUT);
  }
}
