import { Injectable, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as routesList from '@src/app/core/constants/routes.constant';

import { HttpService } from './http.service';
import { LocalStorageService } from './local-storage.service';
import { AppSettingConfig, AwsImageOption } from '@app/app-types';
import {
  CURRENT_USER_DATA,
  APP_CONFIG_KEY,
  DATASET_IMAGE_COUNT_KEY,
  DATASET_ID_BACKGROUND_IMAGE_KEY,
  DATASET_UPLOADED_IMAGE_COUNT_KEY,
  INFERENCE_PROJECT_IMAGE_UPLOAD_STATUS_KEY,
  BATCH_INFERENCE_PROJECT_IMAGE_UPLOAD_STATUS_KEY,
} from '@src/app/core/constants/system.constant';
import { FEATHER, ICONSAX, PERMISSIONS_LIST } from '../constants';
import { MenuItem } from '@src/app/core/interfaces/menu.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  public loadingBarSource = new BehaviorSubject(false);
  private formSubmitButtonSource = new BehaviorSubject(false);
  public loadingBarSourceReset = new BehaviorSubject(false);
  public dataModified = new BehaviorSubject({});
  public searcgBarValue = new BehaviorSubject('');
  loadingBarStatus = this.loadingBarSource.asObservable();
  dataModified$ = this.dataModified.asObservable();

  public appConfigSource: BehaviorSubject<AppSettingConfig> = new BehaviorSubject<AppSettingConfig>(
    this.getDefaultAppConfig(),
  );
  appConfig$ = this.appConfigSource.asObservable();

  public hideNavBar$ = new BehaviorSubject(false);
  public inferenceImageUploadingSource = new BehaviorSubject(false);
  public batchInferenceImageUploadingSource = new BehaviorSubject(false);

  public datasetTotalSelectedFilesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public datasetTotalUploadedFilesCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public appInstanceId$: BehaviorSubject<null | string> = new BehaviorSubject<null | string>(null);
  public datasetImageUploadAppInstanceId$: BehaviorSubject<null | string> = new BehaviorSubject<
    null | string
  >(null);
  public currentFileUploadDatasetId$: BehaviorSubject<null | number> = new BehaviorSubject<
    number | null
  >(null);
  public datasetImageList$: BehaviorSubject<AwsImageOption[]> = new BehaviorSubject<
    AwsImageOption[]
  >([]);

  public currentLanguage: any = 'fr';
  public selectIntercity: any = false;
  public disableIntercity: boolean = true;

  @Output() toggleShowWidget: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Output() buttonClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() isNew: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Initializes a new instance of the class.
   *
   * @param {HttpService} httpService - The HTTP service.
   * @param {TranslateService} translate - The translation service.
   * @param {Router} router - The router service.
   * @param {LocalStorageService} localService - The local storage service.
   */
  constructor(
    public httpService: HttpService,
    private translate: TranslateService,
    public localService: LocalStorageService,
  ) {
    this.datasetTotalSelectedFilesCount$.next(this.localService.get(DATASET_IMAGE_COUNT_KEY) || 0);
    this.datasetTotalUploadedFilesCount$.next(
      this.localService.get(DATASET_UPLOADED_IMAGE_COUNT_KEY) || 0,
    );
    this.currentFileUploadDatasetId$.next(
      this.localService.get(DATASET_ID_BACKGROUND_IMAGE_KEY) || null,
    );
    this.appConfigSource.next(this.localService.get(APP_CONFIG_KEY) || this.getDefaultAppConfig());
    this.inferenceImageUploadingSource.next(
      this.localService.get(INFERENCE_PROJECT_IMAGE_UPLOAD_STATUS_KEY) || false,
    );
    this.batchInferenceImageUploadingSource.next(
      this.localService.get(BATCH_INFERENCE_PROJECT_IMAGE_UPLOAD_STATUS_KEY) || false,
    );
    // Setting user language
    const lang: any = this.localService.get('language', false)?.code?.toLowerCase() || 'fr';
    this.setUserLanguage(lang || 'fr');
    this.currentLanguage = lang || 'fr';
  }

  /**
   * the following method is used to emit for loading bar
   * @param status
   */
  showLoadingBar(status: boolean) {
    setTimeout(() => {
      this.loadingBarSource.next(status);
    }, 150);
  }

  /**
   * the following method is used to emit for loading bar
   * @param submit
   */
  changeFormSubmitStatus(submit: boolean) {
    setTimeout(() => {
      this.formSubmitButtonSource.next(submit);
    }, 150);
  }

  /**
   * the following method is used to set the app theme mode
   * @param config
   */
  setAppConfig(config: AppSettingConfig = this.getDefaultAppConfig()): void {
    if (JSON.stringify(this.appConfigSource.value) !== JSON.stringify(config)) {
      this.appConfigSource.next(config);
      this.localService.set({
        key: APP_CONFIG_KEY,
        value: config,
      });
    }
  }

  /**
   * Retrieves the default application configuration.
   *
   * @return {AppSettingConfig} The default application configuration.
   */
  getDefaultAppConfig(): any {
    const lang: any = this.localService.get('language', false)?.code?.toLowerCase() || 'fr';
    return <AppSettingConfig>{
      theme: 'dark',
      locale: lang || 'fr',
      isSideBarCollapse: false,
      language: this.getDefaultLanguage(),
      layoutDirection: lang === 'ar' ? 'rtl' : 'ltr',
    };
  }

  /**
   * Returns the initial letter of a given name, capitalized.
   *
   * @param {string} name - The name to extract the initial letter from.
   * @return {string} The initial letter of the name, capitalized. If the name is empty or falsy, an empty string is returned.
   */
  getInitialLetter(name: string): string {
    if (name && name.trim().length > 0) {
      return name.trim().charAt(0).toUpperCase();
    } else {
      return '';
    }
  }

  /**
   * the following method is used to set user language
   * @param language
   */
  setUserLanguage(language: any = null): void {
    if (language?.code && ['en', 'fr'].indexOf(language.code.toLowerCase()) === -1) {
      language = this.getDefaultLanguage();
    }
    const appConfig = { ...this.appConfigSource.value };
    appConfig.language = language;
    appConfig.locale = language.code;
    this.setAppConfig(appConfig);
  }

  /**
   * Retrieves the default language from the local service and returns it as an object.
   *
   * @return {any} An object with an 'id' property set to null and a 'code' property set to the default language code or 'fr' if not found.
   */
  private getDefaultLanguage(): any {
    const lang: any = this.localService.get('language', false)?.code?.toLowerCase() || 'fr';
    return {
      id: null,
      code: lang || 'fr',
    };
  }

  public checkPermission = (permissions: any, data: any) => {
    let userData: any = this.localService.get(CURRENT_USER_DATA, false);
    let permissionsOne = new Set(userData?.permissions);
    let filteredArray = permissions.filter((element: any) => permissionsOne.has(element));
    if (!data) {
      return filteredArray.length || [...permissionsOne].length === 0;
    }
    return filteredArray.length || [...permissionsOne].length === 0 ? [data] : [];
  };

  /**
   * Returns an array of menu items based on the user's permissions.
   *
   * @return {MenuItem[]} An array of menu items.
   */
  public getMenu(): MenuItem[] {
    return [
      {
        label: this.translate.instant('MENU.Main'),
        isTitle: true,
      },
      {
        label: this.translate.instant('MENU.DASHBOARD'),
        icon: 'home',
        iconType: FEATHER,
        link: '/dashboard',
      },
      {
        label: this.translate.instant('MENU.MODULE'),
        isTitle: true,
      },

      ...this.checkPermission([PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS], {
        label: this.translate.instant('MENU.USER_MANAGEMENT'),
        iconType: FEATHER,
        icon: 'user',
        link: '',
        subItems: [
          ...this.checkPermission([PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS], {
            label: this.translate.instant('MENU.ADMIN_USERS'),
            link: routesList.ADMIN_USER_LISTING,
          }),
          ...this.checkPermission([PERMISSIONS_LIST.VIEW_USERS, PERMISSIONS_LIST.UPDATE_USERS], {
            label: this.translate.instant('MENU.APP_USERS'),
            link: routesList.APP_USER_LISTING,
          }),
        ],
      }),
      ...this.checkPermission([PERMISSIONS_LIST.VIEW_ROLES, PERMISSIONS_LIST.UPDATE_ROLES], {
        label: this.translate.instant('MENU.PRODUCTS'),
        iconType: ICONSAX,
        icon: 'isax-user-octagon',
        link: routesList.PRODUCTS,
      }),
      ...this.checkPermission([PERMISSIONS_LIST.VIEW_ROLES, PERMISSIONS_LIST.UPDATE_ROLES], {
        label: this.translate.instant('MENU.CATEGORIES'),
        iconType: ICONSAX,
        icon: 'isax-user-octagon',
        link: routesList.CATEGORIES,
      }),
      ...this.checkPermission([PERMISSIONS_LIST.VIEW_ROLES, PERMISSIONS_LIST.UPDATE_ROLES], {
        label: this.translate.instant('MENU.ORDERS'),
        iconType: ICONSAX,
        icon: 'isax-user-octagon',
        link: routesList.ORDERS,
      }),
      ...this.checkPermission([PERMISSIONS_LIST.VIEW_ROLES, PERMISSIONS_LIST.UPDATE_ROLES], {
        label: this.translate.instant('MENU.RETURNS'),
        iconType: ICONSAX,
        icon: 'isax-user-octagon',
        link: routesList.RETURNS,
      }),
      ...this.checkPermission([PERMISSIONS_LIST.VIEW_ROLES, PERMISSIONS_LIST.UPDATE_ROLES], {
        label: this.translate.instant('MENU.PAYMENTS_METHODS'),
        iconType: ICONSAX,
        icon: 'isax-user-octagon',
        link: routesList.PAYMENTS_METHODS,
      }),
      ...this.checkPermission([PERMISSIONS_LIST.VIEW_ROLES, PERMISSIONS_LIST.UPDATE_ROLES], {
        label: this.translate.instant('MENU.DELIVERY_METHODS'),
        iconType: ICONSAX,
        icon: 'isax-user-octagon',
        link: routesList.DELIVERY_METHODS,
      }),

      ...this.checkPermission([PERMISSIONS_LIST.VIEW_ROLES, PERMISSIONS_LIST.UPDATE_ROLES], {
        label: this.translate.instant('MENU.PAGES'),
        iconType: ICONSAX,
        icon: 'isax-user-octagon',
        link: routesList.PAGES,
      }),
      {
        label: this.translate.instant('MENU.SETTINGS'),
        isTitle: true,
      },
      ...this.checkPermission([PERMISSIONS_LIST.VIEW_ROLES, PERMISSIONS_LIST.UPDATE_ROLES], {
        label: this.translate.instant('MENU.SYSTEM_SETTINGS'),
        iconType: ICONSAX,
        icon: 'isax-user-octagon',
        link: routesList.SYSTEM_SETTINGS,
      }),
    ];
  }
}
